import { FunctionComponent } from 'react'
import { Routes, Route } from 'react-router-dom'

import { Dashboard } from './modules/Dashboard/Dashboard'

import { AppLayout } from './components/AppLayout'

export const HubApp: FunctionComponent = () => {
  return (
    <AppLayout>
      <Routes>
        <Route path="*" element={<Dashboard />} />
      </Routes>
    </AppLayout>
  )
}
