import { FunctionComponent } from 'react'
import classnames from 'classnames'

import {
  Button,
  Loader,
  Page,
  Section,
  Typography,
  Icon,
  Table as T,
  Grid,
  CollapsiblePanel,
  Badge
} from '@matillion/component-library'
import { useAuth0 } from '@auth0/auth0-react'

import classes from './Dashboard.module.scss'
const { Table, TableHead, TableBody, TableRow, TableHeaderCell, TableCell } = T

export const Dashboard: FunctionComponent = () => {
  type IconType = 'ok' | 'warn' | 'error'
  interface FieldStatus {
    type: IconType
    info?: string
  }

  const { error, isAuthenticated, isLoading, user, loginWithRedirect, logout } =
    useAuth0()

  const onLogout = () => {
    logout({ logoutParams: { returnTo: window.location.origin } })
  }

  const onLogin = () => {
    loginWithRedirect()
  }
  function getIcon(type: IconType) {
    if (type === 'ok') return <Icon.Success data-testid={'ok-icon'} />
    if (type === 'warn') return <Icon.Warning data-testid={'warn-icon'} />
    return <Icon.Error data-testid={'error-icon'} />
  }
  function getBadge({ type, info }: FieldStatus) {
    if (type === 'error') return <Badge colour={'red'}>{info}</Badge>
    if (type === 'warn') return <Badge colour={'grey'}>{info}</Badge>
  }
  function subStatus(): FieldStatus {
    if (!user?.sub) {
      return { type: 'error', info: 'sub cannot be empty' }
    }
    return user.sub.includes('@')
      ? { type: 'warn', info: 'sub has an @' }
      : { type: 'ok' }
  }
  function emailStatus(): FieldStatus {
    if (!user?.email) {
      return { type: 'error', info: 'email cannot be empty' }
    }
    return user.email.includes('@')
      ? { type: 'ok' }
      : { type: 'warn', info: 'email is missing an @' }
  }
  function emailVerifiedStatus(): FieldStatus {
    return user?.email_verified
      ? { type: 'ok' }
      : { type: 'error', info: 'email must be verified' }
  }
  function nameStatus(): FieldStatus {
    if (!user?.name) {
      return { type: 'warn', info: 'name is empty' }
    }
    return user.name.includes('@')
      ? { type: 'warn', info: 'name contains an @' }
      : { type: 'ok' }
  }
  function givenNameStatus(): FieldStatus {
    if (!user?.given_name && !user?.family_name) {
      return {
        type: 'error',
        info: 'given_name and family_name cannot both be empty'
      }
    }
    return user?.given_name
      ? { type: 'ok' }
      : { type: 'warn', info: 'given_name is empty' }
  }
  function familyNameStatus(): FieldStatus {
    if (!user?.given_name && !user?.family_name) {
      return {
        type: 'error',
        info: 'given_name and family_name cannot both be empty'
      }
    }
    return user?.family_name
      ? { type: 'ok' }
      : { type: 'warn', info: 'family_name is empty' }
  }

  if (error) {
    return (
      <>
        <div>Error: {error.message}</div>
        <br />
        <br />
        <Button
          as="span"
          alt="primary"
          fontWeight="bold"
          onClick={onLogout}
          text={'Logout'}
          size={'lg'}
          data-testid={'logout-button'}
        />
      </>
    )
  }

  if (isLoading) {
    return <Loader data-testid="sso-check-loader" />
  }
  const tableData = [
    { name: 'sub', data: user?.sub, status: subStatus() },
    { name: 'email', data: user?.email, status: emailStatus() },
    {
      name: 'email_verified',
      data: user?.email_verified,
      status: emailVerifiedStatus()
    },
    { name: 'name', data: user?.name, status: nameStatus() },
    { name: 'given_name', data: user?.given_name, status: givenNameStatus() },
    { name: 'family_name', data: user?.family_name, status: familyNameStatus() }
  ]
  return (
    <Page className={classes.Dashboard__Intro}>
      <Grid>
        <Section>
          <Typography as="h1" format="dtm">
            Matillion Hub
          </Typography>
          <Typography as="h2" format="tm">
            Custom SSO Integration Check
          </Typography>
        </Section>
        <Section>
          {isAuthenticated ? (
            <div>
              <Typography as="h2" format="bcm">
                You are logged in as {user?.email}.
              </Typography>
            </div>
          ) : (
            <div>
              <Typography as="h2" format="bcm">
                Click the Login button below to be taken to your SSO
                provider&apos;s login screen.
              </Typography>
            </div>
          )}
        </Section>
        <Section>
          {isAuthenticated ? (
            <Button
              as="span"
              alt="primary"
              fontWeight="bold"
              onClick={onLogout}
              text={'Logout'}
              size={'lg'}
              data-testid={'logout-button'}
            />
          ) : (
            <Button
              as="span"
              alt="primary"
              fontWeight="bold"
              onClick={onLogin}
              text={'Login'}
              size={'lg'}
              data-testid={'login-button'}
            />
          )}
        </Section>
        {isAuthenticated && (
          <Section>
            <Typography as="h2" format="tm">
              Check results
            </Typography>
            <br />
            <Table aria-label="Table Title">
              <TableHead>
                <TableRow>
                  <TableHeaderCell width={'1'}>
                    <Typography weight={'bold'}>Status</Typography>
                  </TableHeaderCell>
                  <TableHeaderCell width={'2'}>
                    <Typography weight={'bold'}>Field</Typography>
                  </TableHeaderCell>
                  <TableHeaderCell width={'9'}>
                    <Typography weight={'bold'}>Value</Typography>
                  </TableHeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map((row) => (
                  <TableRow key={row.name}>
                    <TableCell width={'1'}>
                      {getIcon(row.status.type)}
                    </TableCell>
                    <TableCell width={'2'}>
                      <Typography>{row.name}</Typography>
                    </TableCell>
                    <TableCell width={'9'}>
                      {row.data && (
                        <Typography
                          className={classnames(
                            row.status.type !== 'ok' && classes.Dashboard__Value
                          )}
                        >
                          {row.data}
                        </Typography>
                      )}
                      {getBadge(row.status)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Section>
        )}
        {isAuthenticated && (
          <Section>
            <CollapsiblePanel subtitle="" title="Full Profile">
              <Typography as="pre" className={classes.Dashboard__Code}>
                <code>{JSON.stringify(user, null, 2)}</code>
              </Typography>
            </CollapsiblePanel>
          </Section>
        )}
      </Grid>
    </Page>
  )
}
