/* eslint-disable @typescript-eslint/no-unused-vars */
import './base.scss'

import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Auth0Provider } from '@auth0/auth0-react'

import { config } from './config'
import { HubApp } from './HubApp'

const providerConfig = {
  domain: config.domain || '',
  clientId: config.clientId || '',
  authorizationParams: {
    redirect_uri: window.location.origin,
    ...(config.audience ? { audience: config.audience } : null)
  }
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const rootEl = document.getElementById('root')!
const root = createRoot(rootEl)

rootEl.setAttribute('data-app-environment', config.environment)
rootEl.setAttribute('data-app-version', config.version)

root.render(
  <StrictMode>
    <Auth0Provider {...providerConfig}>
      <BrowserRouter>
        <HubApp />
      </BrowserRouter>
    </Auth0Provider>
  </StrictMode>
)
