import { AuthProviderEnvironment } from '@matillion/hub-client'

const version = process.env.REACT_APP_VERSION ?? '0.0.0-local'

export const getEnvFromUrl = (url: string) => {
  const currentEnv = new URL(url).hostname.split('.')[0].split('-').reverse()[0]
  if (['local', 'test', 'dev', 'preprod'].includes(currentEnv)) {
    return currentEnv
  } else {
    return 'prod'
  }
}

const environment =
  process.env.REACT_APP_ENVIRONMENT === 'test'
    ? process.env.REACT_APP_ENVIRONMENT
    : getEnvFromUrl(window.location.href)

type EnvironmentConfig = {
  AUTH_ENVIRONMENT: AuthProviderEnvironment
  DATADOG?: Record<string, string>
} & {
  [key: string]: string | undefined
}

// eslint-disable-next-line @typescript-eslint/no-var-requires
const envConfig: EnvironmentConfig = require(`./${environment}.json`)

export const config = {
  environment,
  version,
  domain: envConfig.AUTH_DOMAIN,
  clientId: envConfig.AUTH_CLIENTID,
  audience: envConfig.AUTH_AUDIENCE,

  authEnvironment: envConfig.AUTH_ENVIRONMENT,

  gtmId: envConfig.GTM_ID,

  datadog: envConfig.DATADOG && {
    env: envConfig.DATADOG.ENV,
    site: envConfig.DATADOG.SITE,
    clientToken: envConfig.DATADOG.CLIENT_TOKEN,
    service: envConfig.DATADOG.SERVICE,
    rumService: envConfig.DATADOG.RUM_SERVICE,
    applicationId: envConfig.DATADOG.APPLICATION_ID,
    apiOrigin: envConfig.DATADOG.API_ORIGIN
  }
}
