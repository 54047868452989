import { FunctionComponent, PropsWithChildren } from 'react'
import {
  Header,
  Layout,
  MatillionLogo,
  FontProvider
} from '@matillion/component-library'

export const AppLayout: FunctionComponent<PropsWithChildren> = ({
  children
}) => (
  <FontProvider font="gabarito">
    <Layout
      header={
        <Header
          logo={
            <a href="/">
              <MatillionLogo />
            </a>
          }
        />
      }
    >
      {children}
    </Layout>
  </FontProvider>
)
